<template>
  <header>
    <div
      class="container"
      :class="{ small: smallContainer, 'center-logo': centerLogo }"
    >
      <div v-show="showBackButton" @click="goBack()" class="go-back-icon">
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </div>

      <img
        v-show="showLogo"
        @click="$router.push('/')"
        class="header-logo mobile-hidden tablet-hidden pointer"
        :class="{ 'center-img': centerLogo }"
        src="../assets/images/doit-logo.png"
        alt
      />

      <p
        v-show="customPageName"
        class="text-desc text-bold text-center text-blue desktop-hidden"
      >
        {{ customPageName }}
      </p>

      <p
        v-show="!customPageName"
        class="text-desc text-bold text-center text-blue desktop-hidden"
      >
        {{ pageName }}
      </p>

      <div
        v-show="showMenuIcon"
        class="desktop-nav tablet-hidden mobile-hidden"
      >
        <router-link to="/" :class="{ active: $route.path == '/' }">
          <font-awesome-icon :icon="['fas', 'home']" />
          <p class="text-desc">{{ t("Início") }}</p>
        </router-link>
        <router-link
          to="/meusTemplates"
          :class="{ active: $route.path == '/meusTemplates' }"
        >
          <font-awesome-icon :icon="['fas', 'camera']" />
          <p class="text-desc">{{ t("Meus Templates") }}</p>
        </router-link>
        <router-link
          to="/proximos-lancamentos"
          :class="{ active: $route.path == '/proximos-lancamentos' }"
        >
          <font-awesome-icon :icon="['fas', 'clock']" />
          <p class="text-desc">{{ t("Próximos Lançamentos") }}</p>
        </router-link>
      </div>

      <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <div
          @click="showSearch = false"
          v-show="showSearch"
          class="cover-bg"
        ></div>
      </transition>

      <transition
        enter-active-class="animated zoomIn"
        leave-active-class="animated zoomOut"
      >
        <SearchWidget v-show="showSearch" />
      </transition>

      <font-awesome-icon
        v-show="showSearchIcon"
        @click="showSearch = true"
        class="icon-search mr-2-desktop ml-1-desktop"
        :icon="['fas', 'search']"
      />

      <font-awesome-icon
        v-show="showMenuIcon"
        @click="opened = true"
        class="icon-menu pointer"
        :icon="['fas', 'bars']"
      />
    </div>

    <transition enter-active-class="animated fadeIn">
      <div @click="opened = false" v-show="opened" class="cover-bg"></div>
    </transition>
    <div class="sidebar-holder" :class="{ opened: opened }">
      <p @click="opened = false" class="text-desc text-white btn-close pointer">
        X
      </p>

      <router-link to="/" class="mt-2 desktop-hidden">
        <font-awesome-icon style="left: -3px" :icon="['fas', 'home']" />
        <p class="text-desc text-white">{{ t("Início") }}</p>
      </router-link>
      <router-link to="/assinatura" class="mt-2">
        <font-awesome-icon :icon="['fas', 'id-badge']" />
        <p class="text-desc text-white">{{ t("Minha Foto") }}</p>
      </router-link>
      <router-link to="/meusDados" class="mt-2">
        <font-awesome-icon :icon="['fas', 'user']" />
        <p class="text-desc text-white">{{ t("Meus Dados") }}</p>
      </router-link>
      <router-link to="/meusTemplates" class="mt-2 desktop-hidden">
        <font-awesome-icon style="left: -3px" :icon="['fas', 'camera']" />
        <p class="text-desc text-white">{{ t("Meus Templates") }}</p>
      </router-link>
      <router-link to="/proximos-lancamentos" class="mt-2 desktop-hidden">
        <font-awesome-icon style="left: -3px" :icon="['fas', 'clock']" />
        <p class="text-desc text-white">{{ t("Próximos Lançamentos") }}</p>
      </router-link>
      <router-link to="/contato" class="mt-2">
        <font-awesome-icon :icon="['fas', 'lightbulb']" />
        <p class="text-desc text-white">{{ t("Contato") }}</p>
      </router-link>
      <a @click="logout()" class="mt-2 exit pointer">
        <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
        <p class="text-desc text-white">{{ t("Sair") }}</p>
      </a>
    </div>
  </header>
</template>

<script>
import authentication from "../util/authentication";
import SearchWidget from "@/components/SearchWidget";

export default {
  name: "Header",
  components: {
    SearchWidget,
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    showMenuIcon: {
      type: Boolean,
      default: true,
    },
    showSearchIcon: {
      type: Boolean,
      default: true,
    },
    smallContainer: {
      type: Boolean,
      default: false,
    },
    customPageName: {
      type: String,
      default: "",
    },
    centerLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userLoggedIn: false,
      opened: false,
      showSearch: false,
    };
  },
  computed: {
    pageName() {
      return this.$route.name;
    },
  },
  created() {
    this.language = localStorage.language || "br";
    this.$translate.setLang(this.language);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    logout() {
      authentication.logout();
    },
  },
};
</script>
<style lang="scss" scoped>
.center-logo {
  justify-content: center !important;
}
.center-img {
  margin: 0 !important;
}
.desktop-nav {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  a {
    position: relative;
    display: flex;
    height: 28px;
    justify-content: flex-start;
    align-items: center;
    margin-right: 40px;
    font-size: 0.9em;
    svg {
      position: relative;
      font-size: 1.8em;
      color: #fff;
    }
    p {
      position: relative;
      margin-left: 8px;
      color: #fff;
    }
  }
}

.desktop-nav a.active > p,
.desktop-nav a:hover > p,
.desktop-nav a.active > svg,
.desktop-nav a:hover > svg {
  color: var(--brown-light) !important;
}

.icon-search {
  position: relative;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
}

.icon-menu {
  position: relative;
  font-size: 2.2em;
  color: #fff;
}

.go-back-icon {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 45px;
  cursor: pointer;
  svg {
    position: relative;
    font-size: 1.4em;
    color: var(--brown-light);
    left: -1px;
  }
}

.sidebar-holder {
  position: fixed;
  display: block;
  width: 290px;
  height: 100vh;
  background: #c57f5d;
  top: 0;
  right: 0;
  z-index: 10001;
  padding: 32px 32px;
  box-shadow: 1px 1px 12px -4px rgba(0, 0, 0, 0.5);
  transition: transform 0.4s ease;
  transform: translateX(100vw);
  &.opened {
    transform: translateX(0);
  }
  .btn-close {
    position: relative;
    padding: 8px 8px 8px 0;
  }
  a {
    position: relative;
    display: flex;
    width: 100%;
    height: 32px;
    justify-content: flex-start;
    align-items: center;
    &.exit {
      position: absolute;
      bottom: 60px;
    }
    &:hover {
      svg {
        color: var(--blue) !important;
      }
    }
    svg {
      position: relative;
      color: #fff;
      font-size: 1.8em;
    }
    p {
      position: absolute;
      margin-left: 38px;
      color: #fff;
      font-size: 1.6em;
    }
  }
}

@media only screen and (max-width: 720px) {
  .go-back-icon {
    background-color: rgba(122, 63, 45, 0.12);
    margin-right: 18px;
    svg {
      color: var(--blue);
      font-size: 1.4em;
    }
  }
  .icon-search {
    color: var(--blue);
    margin-left: auto;
    margin-right: 40px;
  }
  .icon-menu {
    position: relative;
    font-size: 2em;
    color: var(--blue);
  }
}

@media only screen and (min-width: 721px) and (max-width: 1139px) {
  .icon-search {
    color: var(--blue);
    margin-left: auto;
    margin-right: 40px;
  }

  .icon-menu {
    position: relative;
    font-size: 2.2em;
    color: var(--blue);
  }
}
</style>
