import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login";
import TemplateLista from "@/views/TemplateLista";
import TemplateDetalhes from "@/views/TemplateDetalhes";
import TemplateEdicao from "@/views/TemplateEdicao";
import MeusTemplates from "@/views/MeusTemplates";
import Assinatura from "@/views/Assinatura";
import MeusDados from "@/views/MeusDados";
import TemplateProximos from "@/views/TemplateProximos";
import Pacotes from "@/views/Pacotes";
import PacotesInternacionais from "@/views/PacotesInternacionais";
import RecuperarSenha from "@/views/RecuperarSenha";
import Busca from "@/views/Busca";

Vue.use(VueRouter);

const routes = [
  {
    path: "/novidades",
    name: "Novidades",
    component: () => import("../views/Novidades.vue"),
    meta: {
      title: "Do It - Novidades",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Do It",
    },
  },
  {
    path: "/cadastro/nacional",
    name: "Cadastro",
    component: () => import("../views/Cadastro.vue"),
    meta: {
      title: "Do It - Cadastro",
    },
  },
  {
    path: "/cadastro/internacional",
    name: "CadastroInternacional",
    component: () => import("../views/CadastroInternacional.vue"),
    meta: {
      title: "Do It - Cadastro",
    },
  },
  {
    path: "/termos",
    name: "Terms",
    component: () => import("../views/Terms.vue"),
    meta: {
      title: "Do It - Termos",
    },
  },
  {
    path: "/pre-cadastro",
    name: "PreCadastro",
    component: () => import("../views/PreCadastro.vue"),
    meta: {
      title: "Do It - Cadastro Seleção de País",
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    alias: "/home",
    meta: {
      title: "Do It - Home",
    },
  },
  {
    path: "/templates/:id",
    name: "Templates",
    component: TemplateLista,
    meta: {
      title: "Do It - Templates",
    },
  },
  {
    path: "/template/:id",
    name: "Template",
    component: TemplateDetalhes,
    meta: {
      title: "Do It - Template Detalhes",
    },
  },
  {
    path: "/edicao/:id",
    name: "Edição",
    component: TemplateEdicao,
    meta: {
      title: "Do It - Template Edição",
    },
  },
  {
    path: "/meusTemplates",
    name: "Meus Templates",
    component: MeusTemplates,
    meta: {
      title: "Do It - Meus Templates",
    },
  },
  {
    path: "/assinatura",
    name: "Assinatura",
    component: Assinatura,
    meta: {
      title: "Do It - Template Assinatura",
    },
  },
  {
    path: "/meusDados",
    name: "Meus Dados",
    component: MeusDados,
    meta: {
      title: "Do It - Meus dados",
    },
  },
  {
    path: "/proximos-lancamentos",
    name: "Próximo Mês",
    component: TemplateProximos,
    meta: {
      title: "Do It - Próximos lançamentos",
    },
  },
  {
    path: "/pacotes",
    name: "Pacotes",
    component: Pacotes,
    meta: {
      title: "Do It - Pacotes",
    },
  },
  {
    path: "/pacotes/internacional",
    name: "PacotesInternacionais",
    component: PacotesInternacionais,
    meta: {
      title: "Do It - Pacotes",
    },
  },
  {
    path: "/recuperar-senha",
    name: "RecuperarSenha",
    component: RecuperarSenha,
    meta: {
      title: "Do It - Recuperar Senha",
    },
  },
  {
    path: "/contato",
    name: "Contato",
    component: () => import("../views/Contato.vue"),
    meta: {
      title: "Do It - Contato",
    },
  },
  {
    path: "/busca",
    name: "Busca",
    component: Busca,
    meta: {
      title: "Do It - Busca",
    },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.afterEach((to) => {
  document.title = to.meta.title || "Do It";
  gtag("config", window.GA_TRACKING_ID, {
    page_path: to.fullPath,
    send_page_view: true,
  });
});

export default router;
