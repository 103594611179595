import flags from "../util/flags";
export default {
  countries: [
    {
      country: "Default",
      flag: [flags.masterCard, flags.visa],
    },
    {
      country: "Mexico",
      flag: [flags.amex, flags.masterCard, flags.visa],
    },
    {
      country: "Spain",
      flag: [flags.amex, flags.masterCard, flags.visa, flags.chinaUnionPay],
    },
    {
      country: "United States of America",
      flag: [
        flags.amex,
        flags.masterCard,
        flags.visa,
        flags.diners,
        flags.chinaUnionPay,
        flags.discover,
        flags.jcb,
      ],
    },
  ],

  acceptedFlags(countryName) {
    let setFlags =
      this.countries.filter((el) => el.country === countryName)[0] ||
      this.countries[0];

    return setFlags.flag;
  },
};
