export default {
  amex: {
    title: "American Express",
    url: require("../assets/images/amex.png"),
  },
  masterCard: {
    title: "MasterCard",
    url: require("../assets/images/mastercard.png"),
  },
  visa: {
    title: "Visa",
    url: require("../assets/images/visa-card.png"),
  },
  diners: {
    title: "Diners Club",
    url: require("../assets/images/diners.png"),
  },
  chinaUnionPay: {
    title: "China Union Pay",
    url: require("../assets/images/union-pay.png"),
  },
  discover: {
    title: "Discover",
    url: require("../assets/images/discover.png"),
  },
  jcb: {
    title: "JCB",
    url: require("../assets/images/jcb.png"),
  },
};
